<template>
  <div>
    <select-suggestion
      class="flex-grow w-full"
      model="DynamicReport"
      :label="$t('relatorio_dinamico')"
      :max="10"
      :itemTextFormat="(model) => `${model.id} - ${model.name}`"
      color="dark"
      column="id,name"
      v-model="selectedReport"
      :appendClearOption="true"
      :promptText="$t('informe-o-nome-para-pesquisar')"
      :placeholderText="$t('digite-o-nome-do-relatorio')"
      @selected-model="onReportSelected"
    />

    <vs-divider class="mt-6" v-if="selectedReportDetails">{{ $t('defina_os_filtros') }}</vs-divider>

    <div v-if="selectedReportDetails && selectedReportDetails.filters.length > 0" class="mt-4">
      <div class="grid grid-cols-2 gap-4">
        <div v-for="(filter, index) in selectedReportDetails.filters" :key="index" class="mb-4">
          <label v-if="filter.type === 'date' || filter.type === 'datetime' || filter.type === 'boolean'" :for="filter.field" class="block mb-1">
            {{ filter.label }}
          </label>
          <component
            :is="getComponentForType(filter.type)"
            v-model="filterValues[filter.field]"
            :label="filter.type !== 'date' && filter.type !== 'datetime' ? filter.label : null"
            :class="{'w-full': filter.type !== 'boolean', 'required': filter.required}"
            v-bind="getComponentProps(filter)"
          />
        </div>
      </div>
    </div>

    <div class="w-full flex gap-2 justify-end">
      <vs-button v-if="selectedReportDetails" @click="generateReport" color="primary" class="mt-4">
        {{ $t('gerar_relatorios_dinamicos') }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import DynamicReportExecutionService from '@/services/api/DynamicReportExecutionService'
import DynamicReportService from '@/services/api/DynamicReportService'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  components: {
    SelectSuggestion,
    Datetime
  },
  data: () => ({
    selectedReport: null,
    selectedReportDetails: null,
    filterValues: {},
    reports: [],
    service: null,
    dynamicReportService: null,
    filter: {
      config: null
    },
    key: 0
  }),
  methods: {
    fetchReports(searchQuery) {
      this.dynamicReportService.list({ search: searchQuery }).then(response => {
        this.reports = response.data.map(report => ({
          value: report.id,
          name: report.name
        }))
      }).catch(error => {
        console.error('Error fetching reports:', error)
      })
    },
    onReportSelected(report) {
      if (report) {
        this.dynamicReportService.read(report.id).then(response => {
          this.selectedReportDetails = response
          this.parseFilters()
          this.initializeFilterValues()
        }).catch(error => {
          console.error('Error fetching report details:', error)
        })
      } else {
        this.selectedReportDetails = null
        this.filterValues = {}
      }
    },
    parseFilters() {
      try {
        if (typeof this.selectedReportDetails.filters === 'string') {
          this.selectedReportDetails.filters = JSON.parse(this.selectedReportDetails.filters)
        }
      } catch (error) {
        console.error('Error parsing filters:', error)
        this.selectedReportDetails.filters = []
      }
    },
    initializeFilterValues() {
      this.filterValues = {}
      if (this.selectedReportDetails && this.selectedReportDetails.filters) {
        this.selectedReportDetails.filters.forEach(filter => {
          this.$set(this.filterValues, filter.field, filter.type === 'boolean' ? false : '')
        })
      }
    },
    getComponentForType(type) {
      if (type === 'date' || type === 'datetime') {
        return 'datetime'
      } else if (type === 'boolean') {
        return 'vs-switch'
      }
      return 'vs-input'
    },
    getComponentProps(filter) {
      if (filter.type === 'date' || filter.type === 'datetime') {
        return {
          inputClass: 'vs-inputx vs-input--input normal',
          phrases: { ok: 'Ok', cancel: 'Cancelar' },
          type: filter.type === 'date' ? 'date' : 'datetime',
          format: filter.type === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy hh:mm',
          valueZone: 'America/Sao_Paulo',
          placeholder: filter.type === 'date' ? this.$t('data') : this.$t('data_hora'),
          auto: true
        }
      } else if (filter.type === 'boolean') {
        return {
          color: 'primary',
          label: filter.label
        }
      } else if (filter.type === 'numeric') {
        return {
          type: 'number',
          placeholder: filter.label
        }
      }
      return {}
    },
    generateReport() {
      // Validate required filters
      const missingRequiredFilters = Object.entries(this.filterValues).filter(([field, value]) => {
        const filter = this.selectedReportDetails.filters.find(f => f.field === field)
        return filter.required && !value
      })

      if (missingRequiredFilters.length > 0) {
        this.notifyError(
          this.$vs,
          this.$t('preencha-filtros-obrigatorios')
        )
        return
      }

      this.$vs.loading()

      const filters = Object.entries(this.filterValues).map(([field, value]) => ({
        field,
        value
      }))

      const reportData = {
        reportId: this.selectedReport.id,
        filters
      }

      this.service.create(reportData).then(response => {
        this.$vs.loading.close()
        this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
      }).catch(error => {
        this.$vs.loading.close()
        console.error('Error generating report:', error)
        this.notifyError(
          this.$vs,
          this.$t('nao-foi-possivel-realizar-esta-operacao')
        )
      })
    }
  },
  beforeMount() {
    this.service = DynamicReportExecutionService.build(this.$vs)
    this.dynamicReportService = DynamicReportService.build(this.$vs)
  }
}
</script>
